/****************************************************************************************
                                VARIABLES
*****************************************************************************************/

// BREAKPOINTS
$size-xxxl: 1680px;
$size-xxl: 1440px;
$size-xl: 1280px;
$size-lg: 1024px;
$size-md: 960px;
$size-sm: 800px;
$size-xs: 640px;

// MEDIA QUERY MIXIN
@mixin MQ($canvas) {
	@if $canvas==XXL {
		@media only screen and (max-width: $size-xxxl) {
			@content;
		}
	}
	@if $canvas==XXL {
		@media only screen and (max-width: $size-xxl) {
			@content;
		}
	}
	@if $canvas==XL {
		@media only screen and (max-width: $size-xl) {
			@content;
		}
	}
	@if $canvas==LG {
		@media only screen and (max-width: $size-lg) {
			@content;
		}
	}
	@if $canvas==MD {
		@media only screen and (max-width: $size-md) {
			@content;
		}
	} @else if $canvas==SM {
		@media only screen and (max-width: $size-sm) {
			@content;
		}
	} @else if $canvas==XS {
		@media only screen and (max-width: $size-xs) {
			@content;
		}
	}
}

/***********************************************
PAGE SETTINGS
***********************************************/

$color-white: #ffffff;

$color-blue: #395866;
$color-dark-gray: #434444;
$color-mid-gray: #898680;
$color-light-gray: #e9e9e9;
$color-brown: #6f483d;

$color-black: #000000;

@mixin alpha-attribute($attribute, $color, $background) {
	$percent: alpha($color) * 100%;
	$opaque: opacify($color, 1);
	$solid-color: mix($opaque, $background, $percent);
	#{$attribute}: $solid-color;
	#{$attribute}: $color;
}
