/* rubik-regular - latin */
@font-face {
	font-family: "Rubik";
	font-style: normal;
	font-weight: 400;
	src: local("Rubik"), local("Rubik-Regular"), url("./assets/fonts/rubik-regular.woff2") format("woff2"),
		url("./assets/fonts/rubik-regular.woff") format("woff");
	font-display: swap;
}

/* rubik-medium - latin */
@font-face {
	font-family: "Rubik";
	font-style: normal;
	font-weight: 500;
	src: local("Rubik Medium"), local("Rubik-Medium"), url("./assets/fonts/rubik-medium.woff2") format("woff2"),
		url("./assets/fonts/rubik-medium.woff") format("woff");
	font-display: swap;
}
