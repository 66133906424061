/*!
Site Name: Wunderlich Brillenschmiede Mini
Author: jk
Author URI: https://www.jankoepsel.com
Version: 1.0
*/

/****************************************************************************************
                                NORMALIZE
*****************************************************************************************/
@import "partials/normalize";

/****************************************************************************************
                                VARIABLES
*****************************************************************************************/
@import "partials/variables";

/****************************************************************************************
                                ECO PRINT CSS
*****************************************************************************************/
@import "partials/print";

/****************************************************************************************
                                FONTS
*****************************************************************************************/
@import "partials/fonts";

/****************************************************************************************
                                SLICK STYLES
*****************************************************************************************/
@import "partials/slick";
/****************************************************************************************
                                MAIN STYLES
*****************************************************************************************/
@import "main";