/***********************************************
			GLOBAL STYLES
***********************************************/
html {
	width: 100%;
	height: 100%;
}

body {
	height: 100%;
	padding: 0;
	margin: 0;
	font-family: "Rubik", sans-serif;
	background: $color-mid-gray;
}

a {
	transition: all 0.2s;
}

p,
ol,
ul {
	font-size: 20px;
	line-height: 1.65;
	a {
		color: inherit;
		text-decoration: underline;
		text-decoration-thickness: 2px;
		text-underline-offset: 2px;
		&:hover {
			color: $color-dark-gray;
		}
	}
	@include MQ(MD) {
		font-size: 18px;
	}
}

b,
strong {
	font-family: "Rubik Medium", sans-serif;
}

img {
	max-width: 100%;
	height: auto;
}

main {
	background: transparent;
}

iframe {
	background: $color-white;
}

#zeiss-iframe {
	margin: 0 auto;
	display: block;
	max-height: 643px;
	@include MQ(LG) {
		display: none;
	}
}

h1 {
	font-size: 44px;
	margin: 0;
	letter-spacing: 5px;
	font-weight: normal;
	color: inherit;
	text-transform: uppercase;
	font-family: "Rubik", sans-serif;
	font-weight: 500;
	@include MQ(MD) {
		font-size: 32px;
	}
}

h2 {
	font-size: 30px;
	letter-spacing: 2px;
	font-weight: normal;
	color: inherit;
	text-transform: uppercase;
	font-family: "Rubik", sans-serif;
	font-weight: 500;
	@include MQ(MD) {
		font-size: 24px;
	}
}

h3 {
	font-size: 22px;
	letter-spacing: 2px;
	font-weight: normal;
	color: inherit;
	text-transform: uppercase;
	font-family: "Rubik", sans-serif;
	font-weight: 500;
	@include MQ(MD) {
		font-size: 18px;
	}
}

aside.divider {
	font-size: 12px;
}

/***********************************************
			PARALLAX STYLES
***********************************************/
.parallax-container {
	max-height: 100%;
	min-height: 64vh;
	@include MQ(MD) {
		background-attachment: local !important;
		height: 25vh;
	}
}

/***********************************************
			HEADER & MENU
***********************************************/

header {
	background: $color-dark-gray;
	position: relative;
	.logo {
		max-width: 270px;
		margin: 0 auto;
		display: block;

		@include MQ(MD) {
			max-width: 225px;
		}
		img {
			padding: 40px 0 20px 0;
			height: auto;
		}
	}
	.main-menu {
		border-top: 1px solid $color-light-gray;
		border-bottom: 1px solid $color-light-gray;
		margin: 25px auto 0 auto;
		padding: 0;
		background: inherit;
		@include MQ(SM) {
			position: absolute;
			bottom: 100;
			margin: 0;
			width: 100%;
		}
		.menu-toggle {
			display: none;
			cursor: pointer;
			@include MQ(SM) {
				display: block;
				text-align: center;
				width: 100%;
				padding: 10px 0;
				color: #fff;
			}
			span {
				display: block;
				height: 2px;
				background: $color-light-gray;
				margin: 3px auto;
				max-width: 30px;
				content: "";
			}
		}
		ul {
			list-style: none;
			display: flex;
			margin: 0 auto;
			padding: 5px 0px;
			justify-content: space-around;
			align-items: center;
			font-size: 18px;
			@include MQ(MD) {
				font-size: 16px;
			}
			@include MQ(SM) {
				font-size: 14px;
				display: none;
			}
		}
		li {
			text-align: center;
			border-right: 1px solid $color-light-gray;
			padding: 10px 0;
			flex: 1 1 10%;
			display: flex;
			@include MQ(SM) {
				border: none;
			}
			&:last-of-type {
				border: none;
			}
			&:nth-of-type(5) {
				@include MQ(SM) {
					border-bottom: $color-light-gray solid 1px;
				}
			}
			a {
				color: $color-light-gray;
				text-decoration: none;
				letter-spacing: 3px;
				text-transform: uppercase;
				width: 100%;
				padding: 0 20px;
				border: none;
				font-size: inherit;
				@include MQ(SM) {
					padding: 0;
				}
				&:hover {
					color: $color-mid-gray;
				}
			}
			&.social-icon {
				max-width: 10%;
				@include MQ(SM) {
					width: 49%;
					max-width: 49%;
					display: inline-block;
					border-right: 1px solid $color-light-gray;
					margin: 5px 0;
				}
				&:last-of-type {
					border-right: none;
				}
				a {
					img {
						width: 28px;
						height: 28px;
						vertical-align: middle;
						@include MQ(SM) {
							width: 20px;
							height: 20px;
						}
					}
				}
			}
		}
	}
}

/***********************************************
				SECTIONS
***********************************************/

section {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	justify-content: center;
	background: $color-mid-gray;
	@include MQ(XL) {
	}
	&.section--about {
		@include MQ(MD) {
			.block:last-of-type {
				order: 2;
				grid-row-start: 2;
			}
		}
	}
}

.block {
	padding: 15%;
	grid-column: span 1;
	height: 100%;
	@include MQ(MD) {
		grid-column: 1/-1;
		padding: 5% 10%;
	}
	&.block--flex {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	&.block--wide {
		grid-column: span 2;
		padding: 5% 10%;
		box-sizing: border-box;
	}
	&.block--text {
		text-align: left;
		max-width: 1280px;
		grid-column: span 2;
		margin: 0 auto;
		color: $color-white;
		hyphens: auto;
	}
	&.no-top-pad {
		padding: 0 0 5% 0;
	}
	&.block--border {
		position: relative;
		border-top: 1px solid $color-white;
		padding: 5% 15%;
		&:last-of-type {
			border-bottom: 1px solid $color-white;
		}
	}
	&.block--mid-gray {
		background: $color-mid-gray;
		color: $color-light-gray;
	}
	&.block--brown {
		background: $color-brown;
		color: $color-light-gray;
	}
	&.block--image {
		@include MQ(MD) {
			padding: 10%;
		}
		img {
			object-fit: cover;
		}
	}
}

section.section--news {
	background: $color-mid-gray;
	.content_section_wrap--news {
		width: 100%;
		grid-column: span 2;

		.content_section_item {
			border-top: solid 1px $color-white;
			&:first-of-type {
				border: none;
			}
			h2 {
				font-size: 44px;
				letter-spacing: 5px;
				@include MQ(MD) {
					font-size: 32px;
				}
			}
			a {
				color: $color-light-gray;
				text-decoration: underline;
				text-decoration-thickness: 2px;
				text-underline-offset: 2px;
				&:hover {
					color: $color-dark-gray;
				}
			}
		}
	}

	.content_section_item > div {
		@extend .block;
		@extend .block--wide;
		@extend .block--text;
		@extend .block--mid-gray;
	}
}

.cms section.section--news .content_section_wrap--news .content_section_item {
	display: block;
}

// LOAD MORE SECTION AND BUTTON
.load-more-wrap {
	border-top: 2px solid $color-white;
	grid-column: 1 / span 2;
	padding: 5% 0;
}
#load-more {
	text-align: center;
	border: 2px solid $color-white;
	padding: 20px 65px;
	text-decoration: none;
	color: $color-light-gray;
	font-size: 26px;
	background: none;
	cursor: pointer;
	margin: 0 auto;
	display: block;
	text-transform: uppercase;
	font-weight: 500;
	letter-spacing: 5px;
	transition: all 0.3s;
	@include MQ(MD) {
		font-size: 24px;
	}
	&:hover {
		color: $color-dark-gray;
		border-color: $color-dark-gray;
	}
}

section.section--brands {
	.content_section_wrap--brands {
		display: grid;
		grid-column: span 2;
		border-top: 1px solid $color-white;
		border-bottom: 1px solid $color-white;
		padding: 3% 0;

		.brand-logo {
			@extend .block;
			@extend .block--mid-gray;

			padding: 0;
			max-height: 350px;
			display: flex;
			justify-content: center;
			align-items: center;
			max-height: 350px;
			@include MQ(MD) {
				border: none;
				grid-column: span 2;
				padding: 10% 10% 5% 10%;
			}
			img {
				margin: 0 auto;
				display: block;
			}
		}
	}
	ul li {
		max-width: max-content;
		margin: 0 auto;
	}
	a {
		color: $color-white;
	}
}

.divider {
	text-align: center;
	grid-column: span 2;
	&.divider--small {
		padding: 120px 0 100px 0;
		background: $color-dark-gray;
		color: $color-light-gray;
		@include MQ(MD) {
			padding: 50px 0 50px 0;
		}
	}
	&.divider--big {
		padding: 10% 50px;
		max-height: 500px;
		font-size: 38px;
		letter-spacing: 5px;
		background: $color-light-gray;
		color: $color-blue;
		@include MQ(MD) {
			font-size: 32px;
		}
	}
}

/* Info Banner - Covid ------------------------------------------------------ */

.info-banner {
	font-size: 38px;
	letter-spacing: 1px;
	background: $color-brown;
	color: $color-light-gray;
	padding: 0;
	text-align: center;
	grid-column: span 2;
	display: flex;
	flex-direction: column;
	.editable_line:empty {
		display: none;
	}
	.editable_line:first-child {
		margin: 6% 10% 35px 10%;
		@include MQ(MD) {
			margin: 55px 25px 25px 25px;
		}
	}
	.editable_line:last-child {
		margin: 0 10% 6% 10%;
		@include MQ(MD) {
			margin: 0 25px 55px 25px;
		}
	}
	@include MQ(MD) {
		font-size: 32px;
	}
}

.cms .info-banner {
	.cms_editablearea,
	.editable_line:empty {
		display: block;
		min-width: 25px;
	}
}

/* Open Times --------------------------------------------------------------- */

.open-times {
	@extend p;
	display: flex;
	flex-wrap: wrap;
	dt,
	dd {
		flex: 1 1 50%;
		margin: 0;
	}
}

/***********************************************
				FOOTER
***********************************************/

footer {
	background: $color-mid-gray;
	border-top: 1px solid $color-white;
	nav ul {
		list-style: none;
		display: flex;
		justify-content: space-around;
		margin: 0;
		text-align: center;
		padding: 20px 0;
		text-transform: uppercase;
		li {
			width: 100%;
			padding: 20px 0;
			border-right: 1px solid $color-light-gray;
			@include MQ(SM) {
				padding: 15px 0;
			}
			&:last-of-type {
				border: none;
			}
			a {
				color: $color-white;
				letter-spacing: 2px;
				border: none;
				&:hover {
					color: $color-dark-gray;
				}
			}
		}
	}
}

/***********************************************
				HELPER
***********************************************/

.spaced {
	letter-spacing: 3px;
}

.center-txt {
	text-align: center;
}

.cms_content_section_single_overlay {
	background: rgba(245, 245, 245, 0.85) !important;
	color: rgba(0, 0, 0, 0.6) !important;
	width: 100% !important;
	max-height: 100% !important;
	max-width: 100% !important;
	padding: 0 !important;
	text-align: center !important;
	margin: 0;
}

.cms_button_add_content_section {
	text-align: center;
	margin: 10px auto !important;
	max-width: 400px !important;
	width: 90% !important;
}
